import React from 'react';
import Layout from '../components/Layout';

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(https://images.pexels.com/photos/3184325/pexels-photo-3184325.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)`}}
                    >
                        <h1>Connecting business and technology</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <h1 style={{color: '#000000', fontSize: 20}}></h1>
                            <div>
                                <p className="services_section_1__paragraph" style={{fontSize: 'large'}}>
                                    We help you harness the cutting edge of tech to boost operational efficiencies,
                                    ensure faster time to value, and revolutionize customer care. Our service offering
                                    is built around helping you launch and sustain your digital transformation
                                    long-term.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
